import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Slide } from '../slide';

import {SlidesService} from '../slides.service';


@Component({
  selector: 'app-start-page',
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.scss']
})


export class StartPageComponent implements OnInit, OnDestroy {

  Slides: Slide[]; // из методов компонента мы будем обращаться к этой переменной как this.Slides
  selectedSlide: Slide;
  subscription: Subscription;

  // tslint:disable-next-line:no-shadowed-variable
  constructor(private SlidesService: SlidesService) { // теперь из любого метода мы можем обратиться к сервису как this.SlidesService

  }

  // Этот метод обращается к методу getSlides сервиса SlidesService и передаёт полученные данные в переменную this.Slides.
  getSlides(): void {

    // Подписываемся на получение ожидаемых данных
    this.subscription = this.SlidesService.getSlides()
        .subscribe(result => {
          this.Slides = result;
          // tslint:disable-next-line:max-line-length
          console.log(this.Slides); // (23) [{…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}]
        });
    console.log(this.Slides); // undefined, т.к. файл не был предоставлен сиюсекундно
  }

  // Cохраняем в отдельную переменную друга.
  // В шаблоне эту функцию присвоим обработчику (click).
  // Приложение будет знать, по кому кликнул пользователь. Это понадобится дальше.
  // tslint:disable-next-line:no-shadowed-variable
  selectSlide(Slide: Slide): void {
    this.selectedSlide = Slide;
  }

  ngOnInit() {
    this.getSlides();
  }

  // Уничтожаем подписку при удаление компонента (если таковое в будущем состоится).
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }







  public slides: any = [
    {
      slide: '../../assets/img/slides/slide1.jpg',
      active: 'active'
    },
    {
      slide: '../../assets/img/slides/slide2.jpg'
    },
    {
      slide: '../../assets/img/slides/slide3.jpg'
    },
    {
      slide: '../../assets/img/slides/slide4.jpg'
    },
    {
      slide: '../../assets/img/slides/slide5.jpg'
    },
    {
      slide: '../../assets/img/slides/slide6.jpg'
    }
  ];




}
