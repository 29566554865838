import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-preliminary-block',
  templateUrl: './preliminary-block.component.html',
  styleUrls: ['./preliminary-block.component.scss']
})
export class PreliminaryBlockComponent implements OnInit {

  constructor() { }

  @Input () preliminary;

  ngOnInit() {
  }

}
