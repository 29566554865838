import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-for-business',
  templateUrl: './for-business.component.html',
  styleUrls: ['./for-business.component.scss']
})
export class ForBusinessComponent implements OnInit {

  constructor() { }

  public cards: any = [
    {
      title: 'Кассетные',
      img: '../../assets/img/business/cassette.png',
      description: 'Some information;'
    },
    {
      title: 'Подпотолочные',
      img: '../../assets/img/business/underCeiling.png',
      description: 'Some information;'
    },
    {
      title: 'Канальные',
      img: '../../assets/img/business/canals.png',
      description: 'Some information;'
    },
    {
      title: 'Колонные',
      img: '../../assets/img/business/columned.png',
      description: 'Some information;'
    },
    {
      title: 'Прецизионные',
      img: '../../assets/img/business/precision.png',
      description: 'Some information;'
    },
    {
      title: 'Руфтопы',
      img: '../../assets/img/business/roofTop.png',
      description: 'Some information;'
    },
    {
      title: 'ККБ',
      img: '../../assets/img/business/KKB.png',
      description: 'Some information;'
    }
  ];

  ngOnInit() {
  }

}
