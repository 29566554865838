import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { ForHomeComponent } from './for-home/for-home.component';
import { ForBusinessComponent } from './for-business/for-business.component';
import { StartPageComponent } from './start-page/start-page.component';
import { MultiSystemsComponent } from './multi-systems/multi-systems.component';

const routes: Routes = [
  // { path: '', component: AppComponent},
  { path: '', component: StartPageComponent},
  { path: 'home', component: ForHomeComponent},
  { path: 'business', component: ForBusinessComponent},
  { path: 'multisystems', component: MultiSystemsComponent}
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
      RouterModule
  ]
})
export class AppRoutingModule { }
