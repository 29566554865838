import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  constructor() { }

  brandImg: any = '../../assets/img/midea/mideaLogo.png';

  public titles: any = [
      {
          title: 'Для дома',
          link: '/home',
          subtitle: [
              {
                  title: 'Кондиционеры'
              },
              {
                  title: 'Мульти-сплит'
              },
              {
                  title: 'Тепловые насосы'
              },
              {
                  title: 'Вентиляция'
              }
          ]
      },
      {
          title: 'Для бизнеса',
          link: '/business',
          subtitle: [
              {
                  title: 'Касетные'
              },
              {
                  title: 'Подпотолочные'
              },
              {
                  title: 'Канальные'
              },
              {
                  title: 'Колонные'
              },
              {
                  title: 'Прецизионные'
              },
              {
                  title: 'Руфтопы'
              },
              {
                  title: 'ККБ'
              }
          ]
      },
      {
          title: 'Мультизональные системы',
          link: '/multisystems',
          subtitle: [
              {
                  title: 'Чиллера'
              },
              {
                  title: 'Фанкойлы'
              },
              {
                  title: 'MDV системы'
              },
              {
                  title: 'Внутренние блоки MDV'
              }
          ]
      },
      {
          title: 'Info'
      }

  ];


    ngOnInit() {
  }

}
