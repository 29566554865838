import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-multi-systems',
  templateUrl: './multi-systems.component.html',
  styleUrls: ['./multi-systems.component.scss']
})
export class MultiSystemsComponent implements OnInit {

  constructor() { }

  public cards: any = [
    {
      title: 'Чиллера',
      img: '../../assets/img/multiSystem/chiller.png',
      description: 'Some information;'
    },
    {
      title: 'Фанкойлы',
      img: '../../assets/img/multiSystem/fanCoil.png',
      description: 'Some information;'
    },
    {
      title: 'MDV системы',
      img: '../../assets/img/multiSystem/mdv.png',
      description: 'Some information;'
    }
  ];

  ngOnInit() {
  }

}
