import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-for-home',
  templateUrl: './for-home.component.html',
  styleUrls: ['./for-home.component.scss']
})
export class ForHomeComponent implements OnInit {

  constructor() { }


  public cards: any = [
  {
    title: 'Кондиционеры',
    img: '../../assets/img/home/conditioner.png',
    description: 'Some information;'
  },
  {
    title: 'Мульти-сплит',
    img: '../../assets/img/home/multisplit.png',
    description: 'Some information;'
  },
  {
    title: 'Тепловые насосы',
    img: '../../assets/img/home/heatPump.png',
    description: 'Some information;'
  },
  {
    title: 'Вентиляция',
    img: '../../assets/img/home/rekuperator.png',
    description: 'Some information;'
  }
];






  ngOnInit() {

  }
}


